<template>
  <g>
    <svg:style>
      .cls-4 {
        stroke: #aeaeae;
        stroke-miterlimit: 10;
        stroke-width: 0.25px;
      }

      .cls-4 {
        stroke-linecap: square;
      }

      .cls-4 {
        fill: url(#inox-gradient);
      }
      .Solid { fill: {{ doorSolidColor }} }
      .Molding-L { fill: url(#Molding-L); } /* left */
      .Molding-T { fill: url(#Molding-T); } /* top */
      .Molding-R { fill: url(#Molding-R); } /* right */
      .Molding-B { fill: url(#Molding-B); } /* bottom */
    </svg:style>
    <linearGradient id="handle-gradient"
                    :x1="doorLeftWidth1 + 11.16"
                    :y1="doorTopHeight1 + 148.47"
                    :x2="doorLeftWidth1 + 16.75"
                    :y2="doorTopHeight1 + 148.47"
                    gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stop-color="#babbbc"/>
      <stop offset="0.29" stop-color="#989a9e"/>
      <stop offset="0.65" stop-color="#bdbdc1"/>
      <stop offset="0.87" stop-color="#9d9fa2"/>
      <stop offset="1" stop-color="#a6a8aa"/>
      <stop offset="1" stop-color="#c2c5c9"/>
    </linearGradient>
    <linearGradient
      id="inox-gradient"
      :x1="doorLeftWidth1 + 455.84"
      :y1="doorTopHeight1 + 795.98"
      :x2="doorLeftWidth1 + 455.84"
      :y2="doorTopHeight1 + 568.93"
      xlink:href="#handle-gradient"/>
    <!-- Molding gradient BEGIN -->
    <linearGradient id="Molding-L" data-name="Molding" x1="0" y1="0" x2="100%" y2="0">
      <stop offset="0" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.34" stop-color="#fff" stop-opacity="0.4"/>
      <stop offset="0.61" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.7" stop-color="#fff" stop-opacity="0.1"/>
      <stop offset="0.77" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.86" stop-color="#fff" stop-opacity="0.4"/>
      <stop offset="1" stop-color="#000" stop-opacity="0.5"/>
    </linearGradient>
    <linearGradient id="Molding-T" data-name="Molding" x1="0" y1="0" x2="0" y2="100%">
      <stop offset="0" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.34" stop-color="#fff" stop-opacity="0.4"/>
      <stop offset="0.61" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.7" stop-color="#fff" stop-opacity="0.1"/>
      <stop offset="0.77" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.86" stop-color="#fff" stop-opacity="0.4"/>
      <stop offset="1" stop-color="#000" stop-opacity="0.5"/>
    </linearGradient>
    <linearGradient id="Molding-R" data-name="Molding" x1="100%" y1="0" x2="0" y2="0">
      <stop offset="0" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.34" stop-color="#fff" stop-opacity="0.4"/>
      <stop offset="0.61" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.7" stop-color="#fff" stop-opacity="0.1"/>
      <stop offset="0.77" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.86" stop-color="#fff" stop-opacity="0.4"/>
      <stop offset="1" stop-color="#000" stop-opacity="0.5"/>
    </linearGradient>
    <linearGradient id="Molding-B" data-name="Molding" x1="0" y1="100%" x2="0" y2="0">
      <stop offset="0" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.34" stop-color="#fff" stop-opacity="0.4"/>
      <stop offset="0.61" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.7" stop-color="#fff" stop-opacity="0.1"/>
      <stop offset="0.77" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.86" stop-color="#fff" stop-opacity="0.4"/>
      <stop offset="1" stop-color="#000" stop-opacity="0.5"/>
    </linearGradient>   
    <!-- Molding gradient END -->
    <g id="C14">
      <path
        id="inox"
        v-if="showInox"
        class="cls-4"
        :d="`
        M${doorLeftWidth1 + 421},${doorTopHeight1 + 568.93}
        V${doorTopHeight1 + 796}
        h69.78
        v-227
        H${doorLeftWidth1 + 421}
        Z
        m65,222.08
        H${doorLeftWidth1 + 425.77}
        V${doorTopHeight1 + 573.75}
        h60.14
        V${doorTopHeight1 + 791}
        Z
        `"
        transform="translate(-385.93 -533.49)"
      />
      <!-- Molding BEGIN -->
      <g id="Moldings" v-if="showMolding">
        <g id="Molding" data-name="Molding">
          <polygon
            class="Solid"
            :points="`
              ${doorLeftWidth1 + 100.44} ${doorTopHeight1 + 40.18}
              ${doorLeftWidth1 + 39.65} ${doorTopHeight1 + 40.18}
              ${doorLeftWidth1 + 34.78} ${doorTopHeight1 + 35.74}
              ${doorLeftWidth1 + 105.48} ${doorTopHeight1 + 35.74}
              ${doorLeftWidth1 + 100.44} ${doorTopHeight1 + 40.18}
            `"/>
          <polygon
            class="Solid"
            :points="`
              ${doorLeftWidth1 + 39.82} ${doorTopHeight1 + 259.03}
              ${doorLeftWidth1 + 100.61} ${doorTopHeight1 + 259.03}
              ${doorLeftWidth1 + 105.48} ${doorTopHeight1 + 263.47}
              ${doorLeftWidth1 + 34.78} ${doorTopHeight1 + 263.47}
              ${doorLeftWidth1 + 39.82} ${doorTopHeight1 + 259.03}
            `"/>
          <polygon
            class="Solid"
            :points="`
              ${doorLeftWidth1 + 39.65} ${doorTopHeight1 + 40.18}
              ${doorLeftWidth1 + 39.82} ${doorTopHeight1 + 259.03}
              ${doorLeftWidth1 + 34.78} ${doorTopHeight1 + 263.47}
              ${doorLeftWidth1 + 34.78} ${doorTopHeight1 + 35.74}
              ${doorLeftWidth1 + 39.65} ${doorTopHeight1 + 40.18}
            `"/>
          <polygon
            class="Solid"
            :points="`
              ${doorLeftWidth1 + 100.61} ${doorTopHeight1 + 259.03}
              ${doorLeftWidth1 + 100.44} ${doorTopHeight1 + 40.18}
              ${doorLeftWidth1 + 105.48} ${doorTopHeight1 + 35.74}
              ${doorLeftWidth1 + 105.48} ${doorTopHeight1 + 263.47}
              ${doorLeftWidth1 + 100.61} ${doorTopHeight1 + 259.03}
            `"/>
          <polygon
            class="Molding-T"
            :points="`
              ${doorLeftWidth1 + 100.44} ${doorTopHeight1 + 40.18}
              ${doorLeftWidth1 + 39.65} ${doorTopHeight1 + 40.18}
              ${doorLeftWidth1 + 34.78} ${doorTopHeight1 + 35.74}
              ${doorLeftWidth1 + 105.48} ${doorTopHeight1 + 35.74}
              ${doorLeftWidth1 + 100.44} ${doorTopHeight1 + 40.18}
            `"/>
          <polygon
            class="Molding-B"
            :points="`
              ${doorLeftWidth1 + 39.82} ${doorTopHeight1 + 259.03}
              ${doorLeftWidth1 + 100.61} ${doorTopHeight1 + 259.03}
              ${doorLeftWidth1 + 105.48} ${doorTopHeight1 + 263.47}
              ${doorLeftWidth1 + 34.78} ${doorTopHeight1 + 263.47}
              ${doorLeftWidth1 + 39.82} ${doorTopHeight1 + 259.03}
            `"/>
          <polygon
            class="Molding-L"
            :points="`
              ${doorLeftWidth1 + 39.65} ${doorTopHeight1 + 40.18}
              ${doorLeftWidth1 + 39.82} ${doorTopHeight1 + 259.03}
              ${doorLeftWidth1 + 34.78} ${doorTopHeight1 + 263.47}
              ${doorLeftWidth1 + 34.78} ${doorTopHeight1 + 35.74}
              ${doorLeftWidth1 + 39.65} ${doorTopHeight1 + 40.18}
            `"/>
          <polygon
            class="Molding-R"
            :points="`
              ${doorLeftWidth1 + 100.61} ${doorTopHeight1 + 259.03}
              ${doorLeftWidth1 + 100.44} ${doorTopHeight1 + 40.18}
              ${doorLeftWidth1 + 105.48} ${doorTopHeight1 + 35.74}
              ${doorLeftWidth1 + 105.48} ${doorTopHeight1 + 263.47}
              ${doorLeftWidth1 + 100.61} ${doorTopHeight1 + 259.03}
            `"/>
        </g>
      </g>
      <!-- Molding END -->
    </g>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
    'showMolding',
    'doorSolidColor',
  ],
  computed: {
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth/2 - 70
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight/2 - 148
    },
  }
}
</script>
